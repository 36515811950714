var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "conclusionExamination",
      attrs: {
        title: _vm.modalTitle,
        "mask-closable": false,
        draggable: false,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Row",
        { attrs: { gutter: 16 } },
        [
          _c("Col", { attrs: { span: "12" } }, [
            _c("div", { staticClass: "txt" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    "font-size": "16px",
                    position: "relative",
                    top: "4px",
                  },
                },
                [_vm._v("*")]
              ),
              _vm._v("职业健康体检结论 "),
            ]),
            _c("div", { staticClass: "cardTextareaResult" }, [
              _c(
                "div",
                {
                  staticClass: "textareaResult",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("Input", {
                    staticClass: "cardTextarea",
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 10, minRows: 10 },
                    },
                    model: {
                      value: _vm.inspectForm.conclusionInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.inspectForm, "conclusionInfo", $$v)
                      },
                      expression: "inspectForm.conclusionInfo",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("Col", { attrs: { span: "12" } }, [
            _c("div", { staticClass: "txt" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    "font-size": "16px",
                    position: "relative",
                    top: "4px",
                  },
                },
                [_vm._v("*")]
              ),
              _vm._v("职业健康体检结果 "),
            ]),
            _c("div", { staticClass: "cardTextareaResult" }, [
              _c(
                "div",
                {
                  staticClass: "textareaResult",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("Input", {
                    staticClass: "cardTextarea",
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 10, minRows: 10 },
                    },
                    model: {
                      value: _vm.inspectForm.conclusion,
                      callback: function ($$v) {
                        _vm.$set(_vm.inspectForm, "conclusion", $$v)
                      },
                      expression: "inspectForm.conclusion",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "Row",
        { attrs: { gutter: 16 } },
        [
          _c("Col", { attrs: { span: "12" } }, [
            _c("div", { staticClass: "txt" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    "font-size": "16px",
                    position: "relative",
                    top: "4px",
                  },
                },
                [_vm._v("*")]
              ),
              _vm._v("其他异常描述 "),
            ]),
            _c("div", { staticClass: "cardTextareaResult" }, [
              _c(
                "div",
                {
                  staticClass: "textareaResult",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("Input", {
                    staticClass: "cardTextarea",
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 10, minRows: 10 },
                    },
                    model: {
                      value: _vm.inspectForm.otherCheckAbnormalResults,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inspectForm,
                          "otherCheckAbnormalResults",
                          $$v
                        )
                      },
                      expression: "inspectForm.otherCheckAbnormalResults",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("Col", { attrs: { span: "12" } }, [
            _c("div", { staticClass: "txt" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    "font-size": "16px",
                    position: "relative",
                    top: "4px",
                  },
                },
                [_vm._v("*")]
              ),
              _vm._v("职业健康体检建议 "),
            ]),
            _c("div", { staticClass: "cardTextareaResult" }, [
              _c(
                "div",
                {
                  staticClass: "textareaResult",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("Input", {
                    staticClass: "cardTextarea",
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 10, minRows: 10 },
                    },
                    model: {
                      value: _vm.inspectForm.handleOpinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.inspectForm, "handleOpinion", $$v)
                      },
                      expression: "inspectForm.handleOpinion",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }